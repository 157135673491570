import React from 'react'
import Layout from '../components/Layout/Layout'
import SEO from '../components/seo'
import './pricing.scss'

function pricing() {
  return (
    <Layout>
      <SEO title="Podcise pricing page" />
      <div className="pricing_hero ">
        <h1>Plans & Pricing</h1>
      </div>
      <div className="mt-40">
        <section className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-3">
              <ul className="pricing p-green card center">
                <li className="center">
                  <big>Free</big>
                </li>
                <li className="text-success">
                  30 minutes per month of analysis{' '}
                </li>
                <li className="text-success">All Insights Provided</li>
                <li className="text-error">Download Insights</li>
                <li className="text-error">Early Access to Beta Testing</li>
                <li className="center">
                  <h3>$0</h3>
                  <span>per month</span>
                </li>
                <li className="center">
                  <a
                    className="btn btn__primary"
                    href="https://app.podcise.com/plans"
                  >
                    Join Now
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-xs-12 col-sm-6 col-md-3">
              <ul className="pricing p-green card center">
                <li className="center">
                  <big>Standard</big>
                </li>
                <li className="text-success">
                  10 hours per month of analysis{' '}
                </li>
                <li className="text-success">All Insights Provided</li>
                <li className="text-success">Download Insights</li>
                <li className="text-error">Early Access to Beta Testing</li>
                <li className="center">
                  <h3>$49</h3>
                  <span>per month</span>
                </li>
                <li className="center">
                  <a
                    className="btn btn__primary"
                    href="https://app.podcise.com/plans"
                  >
                    Join Now
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-xs-12 col-sm-6 col-md-3">
              <ul className="pricing p-green card center">
                <li className="center">
                  <big>Business</big>
                </li>
                <li className="text-success">
                  20 hours per month of analysis{' '}
                </li>
                <li className="text-success">All Insights Provided</li>
                <li className="text-success">Download Insights</li>
                <li className="text-success">Early Access to Beta Testing</li>
                <li className="center">
                  <h3>$99</h3>
                  <span>per month</span>
                </li>
                <li className="center">
                  <a
                    className="btn btn__primary"
                    href="https://app.podcise.com/plans"
                  >
                    Join Now
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-xs-12 col-sm-6 col-md-3 ">
              <ul className="pricing p-green card center">
                <li className="center">
                  <big>Custom</big>
                </li>
                <li className="text-success">
                  Contact us so we can create an experience that best suits your
                  needs!
                </li>

                <li className="center">
                  <a
                    className="btn btn__primary"
                    href="https://app.podcise.com/plans"
                  >
                    Join Now
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default pricing
